import React, { useState, useEffect } from "react";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { emptyCartCount } from "../../actions/action";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [varificationEmail, setVarificationEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogin = (token) => {
        localStorage.setItem("token", token);
        const lastVisitedUrl = sessionStorage.getItem("lastVisitedUrl");
        if (lastVisitedUrl) {
            sessionStorage.removeItem("lastVisitedUrl");
            navigate(lastVisitedUrl);
        } else {
            navigate("/");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let body = {
            email,
            password,
        };
        Http.POST("login", body)
            .then(({ data }) => {
                toastSuccess("Successfully Logged In.");
                if (data.accessToken) {
                    localStorage.setItem("isReload", "true");
                    localStorage.setItem("token", data.tokenType + " " + data.accessToken);
                    localStorage.setItem("refreshToken", data.refreshToken);
                    localStorage.setItem("email", email);
                    handleLogin(data.accessToken);
                    Http.GET("userInfo")
                        .then(({ data }) => {
                            localStorage.setItem("userInfo", JSON.stringify(data));
                        })
                        .catch(({ response }) => {
                            handleErrorResponse(response);
                        });
                } else {
                    // Handle the case where there's no accessToken
                }
            })
            .catch(({ response }) => {
                handleErrorResponse(response);
            });
    };

    const sendForgetPasswodRequest = () => {
        let body = {
            email: varificationEmail,
        };
        Http.POST("forgetPassword", body)
            .then(({ data }) => {
                toastSuccess("Successfull send email. Please check your email inbox");
            })
            .catch(({ response }) => {
                handleErrorResponse(response);
            });
    };

    function handleErrorResponse(response) {
        let message = "Something went wrong!"; // Default message
        try {
            // Attempt to parse the response data as JSON
            //let data = JSON.parse(response.data.message);
            let data = response.data.message;
            console.log(data);
            if (data?.length > 0 && data[0]?.defaultMessage != undefined) {
                message = data[0]?.defaultMessage;
            } else if (data) {
                message = data;
            }
        } catch (error) {
            // If parsing as JSON fails, use the default message
        }
        toastError(message);
    }

    //   useEffect(() => {
    //     dispatch(emptyCartCount());
    //   }, []);

    return (
        <section className="register-section login">
            <div className="container-fluid">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-lg-12 px-0 login-info-container">
                        <div className="card text-black border-0">
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="login-thambnail">
                                        <img src="/images/login.jpg" alt="login" />
                                    </div>
                                    <div className="form-info">
                                        <p className="text-center d-flex align-items-center justify-content-between h2 fw-bold mx-1 mx-md-4 mt-4">
                                            <span>Login</span>
                                            <img
                                                src="/icons/rebuild-logo.png"
                                                alt="logo"
                                                className="logo"
                                            />
                                        </p>
                                        {isForgotPassword ? (
                                            <form className="form-list mx-1 mx-md-4">
                                                <div className="mb-3">
                                                    <div className="form-outline flex-fill mb-0">
                                                        <label
                                                            className="form-label mb-0"
                                                            htmlFor="email"
                                                        >
                                                            Your Email
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            onChange={(e) =>
                                                                setVarificationEmail(e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                    <button
                                                        type="button"
                                                        className="button green"
                                                        onClick={sendForgetPasswodRequest}
                                                    >
                                                        Send Email
                                                    </button>
                                                </div>
                                            </form>
                                        ) : (
                                            <form className="form-list mx-1 mx-md-4">
                                                <div className="mb-3">
                                                    <div className="form-outline flex-fill mb-0">
                                                        <label
                                                            className="form-label mb-0"
                                                            htmlFor="email"
                                                        >
                                                            Your Email
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            onChange={(e) =>
                                                                setEmail(e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="form-outline flex-fill mb-0">
                                                        <label
                                                            className="form-label mb-0"
                                                            htmlFor="password"
                                                        >
                                                            Password
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            id="password"
                                                            autoComplete="current-password"
                                                            onChange={(e) =>
                                                                setPassword(e.target.value)
                                                            }
                                                        />
                                                        <div className="text-end mt-1">
                                                            <span
                                                                className="regular-14 dark_02 cursor-pointer"
                                                                onClick={() =>
                                                                    setIsForgotPassword(true)
                                                                }
                                                            >
                                                                Forgot password?
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                    <button
                                                        type="button"
                                                        className="button green"
                                                        onClick={handleSubmit}
                                                    >
                                                        Login
                                                    </button>
                                                </div>
                                                <p className="">
                                                    Don't have a account?{" "}
                                                    <NavLink to={"/register"}>Register Now</NavLink>{" "}
                                                </p>
                                            </form>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
