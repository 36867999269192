import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Http from "../utils/Http";
import { toastError } from "../utils/Toast";
import { CART_SESSION_NAME } from "../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import addToCartCount from "../actions/action";

const Header = () => {
  const [showCatMenu, setShowCatMenu] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const [type, setType] = useState("");
  const [categoryList, setcategoryList] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const navigate = useNavigate();
  const subMenuRef = useRef(null);
  const { cartElementcount } = useSelector((state) => state.updateCart);
  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const cartSession = localStorage.getItem(CART_SESSION_NAME);
  const token = localStorage.getItem("token");

  const isLogin = () => {
    return !!token;
  };

  const typeChange = (type) => {
    setType(type);
    getCategoryList(type, true);
    setActiveItem(type);
    setShowCatMenu(true);
  };

  const getCategoryList = (type, isRootCategory = null) => {
    let param = `?type=${type}&isShowOnCustomer=true`;
    if (isRootCategory != null) {
      param += `&isRootCategory=${isRootCategory}`;
    }
    Http.GET("categoryList", param)
      .then((response) => {
        let data = response.data;
        setcategoryList(data.data);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const setSubmenu = (id) => {
    let param = `?parentId=${id}&isShowOnCustomer=true`;
    Http.GET("categoryList", param)
      .then((response) => {
        let data = response.data;
        let temCategory = [...categoryList];
        let category = temCategory.find((item) => item.id === id);
        if (data.totalElements == 0) {
          goCatpage(category);
        }
        category["subcategory"] = data.data;
        setcategoryList(temCategory);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const goCatpage = (subItem) => {
    let catName = subItem.name.toLowerCase().replace(/\s/g, "");
    navigate(`/${subItem.type.toLowerCase()}/${catName}/${subItem.id}`);
    setShowCatMenu(false);
    setActiveItem("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (subMenuRef.current && !subMenuRef.current.contains(event.target)) {
        setShowCatMenu(false);
        setActiveItem("");
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [subMenuRef]);

  const getCartItems = () => {
    if (isLogin()) {
      Http.GET("checkCartSession")
        .then((response) => {
          const data = response.data;
          dispatch(addToCartCount({ count: data.cartCount }));
          if (data.cartSession !== undefined)
            localStorage.setItem(CART_SESSION_NAME, data.cartSession);
        })
        .catch((response) => {
          toastError(response.message);
        });
    }

    if (!isLogin() && cartSession) {
      Http.GET("getCartItems", cartSession)
        .then((response) => {
          const data = response.data;
          dispatch(addToCartCount({ count: data.length }));
        })
        .catch((response) => {
          toastError(response.message);
        });
    }
  };

  const Logout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("selectedShippingAddress");
    localStorage.removeItem("selectedShippingAddress");
    localStorage.removeItem(CART_SESSION_NAME);
    window.location.href = "/login";
  };



  useEffect(() => {
    getCartItems();
  }, []);

  return (
    <header className="header-section">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <NavLink to="/">
            <img src="/icons/rebuild-logo.png" alt="logo" className="logo" />
          </NavLink>
          
          <div className="menus" ref={subMenuRef}>
            <ul className="main-menu">
              <li
                className={activeItem === "BUY" ? "active" : ""}
                onClick={() => typeChange("BUY")}
              >
                <span>Buy</span>
              </li>
              <li
                className={activeItem === "SELL" ? "active" : ""}
                onClick={() => typeChange("SELL")}
              >
                <span>Sell</span>
              </li>
              {/*<li
                className={activeItem === "REPAIR" ? "active" : ""}
                onClick={() => typeChange("REPAIR")}
              >
                <span>Repair</span>
              </li>*/}
            </ul>
            {showCatMenu && (
              <div className="sub-category-menu">
                {categoryList &&
                  categoryList.map((item) => (
                    <div className="dropdown" key={item.id}>
                      <button
                        className=""
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => setSubmenu(item.id)}
                      >
                        <img
                          src={item?.logo?.docUrl}
                          alt="phone"
                          className="cat-image"
                        />
                        <p>{item?.name}</p>
                        <img
                          src="/icons/chevron-down.svg"
                          className="down-arrow"
                        />
                      </button>
                      <ul className="dropdown-menu">
                        {item?.subcategory &&
                          item?.subcategory.map((subItem) => (
                            <li
                              key={subItem.id}
                              onClick={() => goCatpage(subItem)}
                            >
                              {subItem?.name}
                            </li>
                          ))}
                      </ul>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="extra-menus">
            
            <ul>
              <li>
              <div className="" id="google_translate_element"></div>
                            </li>
              <li>
                <p
                  className="cart cursor-pointer"
                  onClick={() => navigate("/cart")}
                >
                  <img src="/icons/shopping-bag.svg" alt="shopping-bag" />
                  {cartElementcount != null && cartElementcount > 0 && (
                    <span className="cart-count">{cartElementcount}</span>
                  )}
                </p>
              </li>
              <li>
                {userInfo?.name ? (
                  <div className="dropdown">
                    <NavLink
                      className="d-flex align-items-center"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="false"
                      aria-expanded="false"
                    >
                      <div>
                        <div className="avatar avatar-sm rounded-circle text-white">
                          <span className="user-name">
                            {userInfo?.name.charAt(0)}
                          </span>
                        </div>
                      </div>
                      <div className="d-none d-sm-block ms-3"></div>
                      <div className="d-none d-md-block ms-md-2">
                        <i className="bi bi-chevron-down text-muted text-xs" />
                      </div>
                    </NavLink>
                    <div className="dropdown-menu p-0 dropdown-menu-end profile-dropdown-menu">
                      <ul className="user-other-dropdown">
                        <li onClick={() => navigate("/profile")}>
                          <span className="dropdown-item cursor-pointer">
                            Profile
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <span
                            className="dropdown-item cursor-pointer"
                            onClick={Logout}
                          >
                            Logout
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <img
                    src="/icons/log-in.svg"
                    alt="login"
                    className="cursor-pointer"
                    onClick={() => navigate("/login")}
                  />
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
